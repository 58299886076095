.docContainer {
    background-color: var(--background-secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.docBox{
    background: var(--background-primary);
    color: black;
    width: 90%;
    border-radius: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    padding: 0 0 18px;
}

.docBox > h1 {
    padding: 70px;
    font-size: 60px;
    transition: all 0.5s ease;
}

.docBox > h2 {
    padding: 30px;
    text-align: left;
    font-size: 40px;
    margin-left: 40px;
    text-decoration: underline;
    margin-bottom: 45px;
}

.docBox > p {
    padding: 30px;
    text-align: left;
    font-size: 20px;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: -100px;
}

.docBox > p1 {
    padding: 70px;
    font-size: 16px;
    font-weight: 800;
}

.docBox > h3 {
    font-size: 20px;
    font-weight: 400;
    /*top right bottom left*/
    padding: 30px 0 20px 3em;
}

.docBox > h4 {
    padding: 30px;
    text-align: left;
    font-size: 25px;
    margin-left: 40px;
    font-style: italic;
    font-weight: 300;
    margin-bottom: -30px;
}

.streetDesign {
    display: flex;
    flex-direction: column;
}

.streetDesign > h1 {
    margin-left: 65px;
    padding: 5px;
    font-size: 30px;
    font-weight: bold;
    font-style: italic;
}

.designInfo {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items:center;
    margin-left: 70px;
    height:fit-content;
    margin-bottom: 10px;
}

.designInfo > p {
    max-width: 400px;
    font-size: 18px;
    margin: 50px;
}

.designInfo > img {
    transition: width 1s, height 1.5s;
}

.designInfo > img:hover {
    width: 40%;
}

.greyBox {
    background-color: var(--background-dark);
    width: fit-content;
    /* margin-left: 70px; */
    padding: 20px 50px;
    border-radius: 30px;
    line-height: 30px;
    font-size: 20px;
    margin-bottom: 25px;
    transition:ease 0.5s;
}

.greyBox:hover {
    /* font-size: 22px; */
    font-size: 150%;
}

.thingsInColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.thingsInColumn > p1 {
    /*top right bottom left*/
    padding: 10px 0 0 0;
    font-size: 16px;
    font-weight: 800;
}

.thingsInColumn > img {
    transition: width 1s, height 1.5s;
}

.thingsInColumn > img:hover {
    width: 40%;
}

.greenBox {
    background-color: var(--background-light);
    width: fit-content;
    margin-left: 70px;
    padding: 20px 50px;
    border-radius: 30px;
    line-height: 30px;
    font-size: 20px;    
    transition:ease 0.5s;
}
.greenBox:hover {
    font-size: 22px;
}

#myTable {
    padding: 30px 0 20px 3em;
    margin-top: -30px;
}

#myTable td, #myTable th {
    border: 1px solid var(--background-primary);
    padding: 8px;
  }

#myTable tr:hover {background-color: #ddd;}
#myTable th {
padding-top: 12px;
padding-bottom: 12px;
text-align: left;
background-color: var(--background-dark);
font-weight:normal;
}

.conditionRating {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.rating {
    background-color: var(--background-secondary);
    padding: 20px;
    border-radius: 30px;
    width: 85%;
    margin: 10px;
}
.ratingTitle {
    font-size: 30px;
    font-weight: bold;
    padding: 5px;
}
.ratingDescription {
    font-size: 20px;
    line-height: 24px;
}

.formula {
    font-style: italic;
    font-size: 25px;
    text-align: center;
}

#sub{
    vertical-align: sub;
    font-size: medium;
}
#super{  
    vertical-align:super;  
    font-size: medium;  
}  

.centerImage {
    display: flex;
    justify-content: center;
}

.typesOfCosts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.quantities {
    background-color: var(--background-dark);
    padding: 20px;
    border-radius: 30px;
    width: 85%;
    margin: 10px;
}
.quantityTitle {
    font-size: 25px;
    font-weight: bold;
    padding: 5px;
}
.quantityDescription {
    font-size: 18px;
    line-height: 24px;
}

.importanceMetrics {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.metrics {
    background-color: var(--background-dark);
    padding: 20px;
    border-radius: 30px;
    width: 85%;
    margin: 10px;
}
.metricTitle {
    font-size: 30px;
    font-weight: bold;
    padding: 5px;
}
.metricDescription {
    font-size: 18px;
    line-height: 24px;
}

.instructions {
    background-color: var(--background-dark);
    width: 90%;
    padding: 20px 50px;
    border-radius: 30px;
    line-height: 30px;
    font-size: 20px;
    margin-bottom: 25px;
}
