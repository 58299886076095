:root {
    --color-primary: #19c37d;
    --color-secondary: #715fde;
    --color-error: #ef4146;
    --gradient-primary: linear-gradient(90deg, #a29bd4, #989fdd);
    --background-primary: #e1d7c6;
    --background-secondary: #ece8dd;
    --background-highlight: #579bb1;
    --background-light: #f8f4ea;
    --background-dark: #d2c8b8;

    --dark-primary: #202123;
    --dark-default: #353740;
    --dark-secondary: #6e6e80;
    --dark-disabled: #acacbe;
    --text-error: var(--color-error);
}

.application {
    background-color: var(--background-secondary);
    max-width: 100vw;
    overflow: hidden;
}

.sideBar {
    background-color: var(--background-primary);
}

.charts {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-evenly; */
    /* margin-top: 40px;
    margin-bottom: 100px;
    margin-left: 30px; */
}

.intro {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.intro > h1 {
    color: black;
    max-width: 80%;
    padding: 20px;
    text-align: center;
    font-size: 50px;
}

.toolbar {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    padding: 0 20px;
}

.accessibility-data-source {
    width: clamp(50px, 20%, 250px);
}

.navla {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.textBox {
    background-color: var(--background-dark);
    width: 30vw;
    height: 8vh;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 10px;
}

.textBoxTall {
    background-color: var(--background-dark);
    width: 30vw;
    height: 20vh;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 10px;
}

.costAnalysisText {
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.costAnalysisHeader {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding-right: 50px;
    padding-left: 50px;
}

.popupRow {
    display: flex;
    justify-content: flex-start;
}

.popup {
    padding-left: 5px;
}

.evacData {
    padding-top: 10px;
    list-style-type: none;
}

.evacDataContainer {
    border-radius: 20px;
    padding: 30px;
    background-color: var(--background-primary);
    margin-right: 10px;
}

.map-container {
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    height: 500px;
}

.svbutton {
    size: 5000px;
    position: absolute;
    top: 730px;
    right: 450px;
    margin-left: 20px;
}

.marker {
    background-image: url('https://docs.mapbox.com/mapbox-gl-js/assets/washington-monument.jpg');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.mapboxgl-popup {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.chart {
    margin-left: 20px;
}

.map {
    display: flex;
    flex-direction: row;
    padding: 40px;
}

#leafletTooltip {
    background-color: red;
}

.streetView {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.col {
    display: flex;
    flex-direction: column;
}

.colSide {
    display: flex;
    flex-direction: column;
    width: 19vw;
}

.filterRow {
    display: flex;
    padding-bottom: 2vh;
    justify-content: center;
}
.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: var(--background-dark);
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    top: 200px;
    margin-left: auto;
    border-radius: 4px;
}

#downloadDrop {
    z-index: 999;
}

.row {
    padding-top: 2vh;
    padding-bottom: 2vh;
    display: flex;
    justify-content: space-around;
}

.downloadButton {
    z-index: 1;
}

.centerVertical {
    display: flex;
    justify-content: center;
}

.divider {
    margin-top: 30px;
    margin-bottom: 30px;
}

.keyRow {
    width: 60vw;
    padding-right: 30px;
    display: flex;
    justify-content: center;
}

.twoCol {
    display: flex;
    align-items: center;
}

.filters {
    margin-left: 1vw;
    margin-right: 1vw;
    display: flex;
    align-items: center;
    /* background-image: linear-gradient(rgb(160, 220, 237), rgb(152, 198, 211)); */
}

.filter {
    margin-top: 2px;
    border-radius: 20px;
    padding: 4px;
    background-color: var(--background-primary);
    list-style-type: none;
}

.filterHeader {
    border-radius: 20px;
    background-color: var(--background-primary);
    list-style-type: none;
}

.divBar {
    margin-top: 5px;
    margin-bottom: 5px;
}

.divBar {
    margin-top: 20px;
    margin-bottom: 20px;
}
.space {
    margin-bottom: 10vh;
}

.poTree {
    padding-left: 1vw;
}

.submit {
    margin: 15px;
    display: flex;
    justify-content: space-around;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.setting {
    width: 90px;
    height: 20px;
    border-radius: 50px;
    background-color: #d9d9d9;
    margin-left: 30px;
    margin-right: 30px;
}

Accordion {
    background-color: transparent;
}