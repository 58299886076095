.hero-background {
    /* background: url('/public/images/landing_v2.png'); */
    /* background: url('/public/images/background.png'); */
    background-color: var(--background-secondary);
    display: flex;
    justify-content: space-around;
}

.hero-container {
    /* background: url('/public/images/landing.png'); */
    /* background-position: center; */
    display: flex;
    flex-direction: column;
    /* object-fit: fill; */
    /* background-size: cover;
    background-repeat: no-repeat; */
}

.hero-container > h1 {
    color: black;
    font-size: 100px;
    margin-left: 100px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    margin-top: 100px;
}

.hero-container > p {
    color: black;
    font-size: 50px;
    margin-left: 100px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.description-box {
    background-color: var(--background-primary);
    /* height: 150px; */
    padding: 20px;
    border-radius: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 100px;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.mapImage{
    border-radius: 20px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5vw;
    display: flex;
}

.textbox {
    line-height: 1.3rem;
    width: 50vw;
    font-size: 22px;
    padding: 5px;
}

.hero-btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 80px;
    }

    .textbox {
        font-size: 14px;
    }
}
