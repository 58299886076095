
.container {
    display: flex;
    align-items:baseline;
    gap: 100px;
 
  }

.dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 200px;
    width: 500px;
    border: 4px dashed;
    padding: 20px;
    text-align: center;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 200px;
}

.response-text {
    text-align: center; /* Optional: Center text */
    font-size: 16px; /* Optional: Adjust text size */
}

.preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 500px;
    width: 500px;
    margin: auto;
    margin-top: 20px;
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 20px;

    
}

.title p {
    margin-bottom: 20px; 
}


.placeholder-image, .preview-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content; /* Adjust the width to fit the content */
    height: fit-content; /* Adjust the height to fit the content */
    margin: auto; /* Center the placeholder horizontally */
    object-fit: cover;
    border: 2px solid #ccc;
    border-radius: 10px;
    margin-bottom: 20px;
    max-width: 700px;
}

.upload-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content; /* Adjust the width to fit the content */
    height: fit-content; /* Adjust the height to fit the content */
    margin: 0 auto;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
    margin: auto;
    margin-bottom: 100px;
}


.title {
    margin-bottom: 20px;
}

.title h1 {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #242424;;
}

.title p {
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.analysis {
    font-size: 1rem;
    color: #666;
    max-width: 800px; /* Set a maximum width for the text */
    margin: 0 auto; /* Center the text block horizontally */
    word-wrap: break-word; /* Ensure long words or URLs break */
    line-height: 1.5;
    
}

.upload-button:hover {
    background-color: #0056b3;
}
/* .dropzone:hover {
    border-color: #aaaaaa;
}

.dropzone p {
    align-self: center;
    margin: 0;
    height: 200px;
}

.dropzone:focus,
.dropzone.is-drag-active {
    border-color: #2196f3;
} */
