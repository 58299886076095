.footerContainer {
    background-color: var(--dark-secondary);
    padding: 2rem 0 2rem 0;
    width: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 999;
    position: relative;
}

.footerBar {
    background-color: var(--dark-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* max-width: 1000px; */
    /* margin: 40px auto 0 auto; */
    margin-bottom: -30px;
}

.footerInfo {
    display: flex;
    flex-direction: row;
    margin-left: 50px;
}

.InfoItem {
    margin-right: 40px;
    max-width: 300px;
    color: white;
}

.InfoItem > h1 {
    font-size: 25px;
    margin-bottom: 10px;
    font-weight: 400;
}

.InfoItem > p {
    font-size: 15px;
    padding-bottom:4%;
}

.InfoItem a, .InfoItem a:link, .InfoItem a:visited {
    color:white;
    transition: color 0.3s ease;
}

.InfoItem a:hover {
    color:lightgray;
}

.website-rights {
    color: white;
    margin: 10px;
    font-size: 20px;
}