.backgroundContainer {
    width: 100%;
    padding-top: 15vh;
    background-color: var(--background-secondary);
    padding-bottom: 5vh;
    /* background-color: transparent; */
}

.backgroundBox {
    background: var(--background-primary);
    color: black;
    width: 90%;
    /* height: 600px; */
    border-radius: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

.backgroundBox > h1 {
    padding-top: 40px;
    margin-left: 4vw;
    font-size: 60px;
    transition: all 0.5s ease;
}

.backgroundBox > p {
    padding: 30px;
    font-size: 30px;
    margin-left: 40px;
    margin-right: 40px;
}

@media screen and (max-width: 960px) {
    .backgroundBox {
        height: auto;
    }
    .backgroundBox > h1 {
        padding: 40px;
        text-align: center;
        font-size: 50px;
    }
    .backgroundBox > p {
        padding: 10px;
        text-align: center;
        font-size: 18px;
    }
}

